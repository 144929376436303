import Icon_Barcode from "../images/icon_barcode.png"
import Icon_Suporte from "../images/icon_suporte.png"
import Icon_WhatsApp from "../images/icon_whatsapp.png"
import Icon_Web from "../images/icon_web.png"
import Icon_Facebook from "../images/icon_facebook.png"
import Icon_Instagram from "../images/icon_instagram.png"
import Icon_Smile from "../images/icon_smile.png"

export const DATALINKS = [
  {
    id: 1,
    name: "2ª via de boleto",
    image: Icon_Barcode,
    describe: "Via boleto",
    uri: "https://minhajnnet.jnnet.com.br",
  },
  {
    id: 2,
    name: "Suporte",
    image: Icon_Suporte,
    describe: "Suporte",
    uri: "https://minhajnnet.jnnet.com.br",
  },
  {
    id: 3,
    name: "Assine por WhatsApp",
    image: Icon_WhatsApp,
    describe: "WhatsApp",
    uri: "https://api.whatsapp.com/send?phone=5527998921867",
  },
  {
    id: 4,
    name: "Assine pelo site",
    image: Icon_Web,
    describe: "Assine pelo site",
    uri: "https://assine.jnnet.com.br/",
  },
  {
    id: 5,
    name: "Facebook",
    image: Icon_Facebook,
    describe: "Facebook",
    uri: "https://www.facebook.com/jnnetinternet",
  },
  {
    id: 6,
    name: "Instragram",
    image: Icon_Instagram,
    describe: "Instagram",
    uri: "https://www.instagram.com/jn_internet/",
  },
  {
    id: 7,
    name: "Tenho interesse",
    image: Icon_Smile,
    describe: "Tenho interesse",
    uri: "https://jnnet.gr8.com/",
  },
]
