import React from "react"
import styled from "styled-components"
import Layout from "../layout/Layout"

import Logo from "../images/logo_jnnet.png"
import backgroundJnnet from "../images/backgroundJnnet.png"

import { DATALINKS as data } from "../utils/dataLinks"
import Link from "../components/Links/Links"

const NavigationScreen = () => {
  return (
    <Layout title="Navegacao" header={false} footer={false}>
      <StyledBackgroundAt3Section>
        <Conteiner>
          <LogoImg src={Logo} alt="logo Jnnet" />
          <Title>BEM-VINDO!</Title>
          <SubTitle>Fique por dentro das novidades</SubTitle>
          {data.map(item => (
            <Link
              key={item.id}
              text={item.name}
              image={item.image}
              describe={item.describe}
              uri={item.uri}
            />
          ))}
        </Conteiner>
      </StyledBackgroundAt3Section>
    </Layout>
  )
}

const StyledBackgroundAt3Section = styled.div`
  width: 100vw;
  height: 100vh;
  background: url("${backgroundJnnet}");
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 760px) {
    justify-content: flex-start;
  }
`

const Conteiner = styled.div`
  width: 263px;
  margin: 0 5%;
  padding-bottom: 120px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  @media (min-width: 760px) {
    width: 263px;
    margin-left: 180px;
  }
`

const LogoImg = styled.img`
  width: 219px;
  height: 128px;
`

const Title = styled.h1`
  font-family: Montserrat;
  font-weight: bolder;
  font-style: 600;
  font-size: 32px;
  color: #1c3c46;

  margin-top: 39px;
  align-self: flex-start;
`

const SubTitle = styled.small`
  font-family: Montserrat;
  font-size: 16px;
  color: #1c3c46;

  margin-bottom: 27px;
  align-self: flex-start;
`

export default NavigationScreen
