import React from "react"
import styled from "styled-components"

const Links = ({ text, image, uri, describe }) => {
  return (
    <>
      <ItemLink href={uri}>
        <Content>
          <Item>
            <Icon src={image} alt={describe} />
          </Item>
          <Text>{text}</Text>
        </Content>
      </ItemLink>
    </>
  )
}

const ItemLink = styled.a`
  width: 263px;
  height: 32px;
  margin-bottom: 11px;
  text-decoration: none;
  align-self: center;
`

const Content = styled.div`
  width: 100%;
  height: 100%;

  border-radius: 16px;
  background-color: #1c3c46;

  display: flex;
  flex-direction: row;
  align-items: center;
`

const Item = styled.div`
  width: 26px;
  height: 26px;
  background: #fff;
  border-radius: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0px 13px 0px 4px;
`

const Text = styled.span`
  font-family: Montserrat, regular;
  font-size: 14px;
  color: #fff;
`

const Icon = styled.img`
  width: 15px;
  height: 16px;
`

export default Links
